/***************** DATE DATEPICKER *****************/
.bs-datepicker {
  .bs-datepicker-head { background-color: var(--background-primary) !important; }

  .bs-datepicker-body {
    th { color: var(--font-primary) !important; }

    td.week span{ color: var(--font-secondary) !important; }

    .bs-datepicker-body table td:not(.disabled):not(.week)
    span:not(.disabled):not(.is-other-month){ color: var(--button-secondary-font) !important; }
  }
}

/***************** TIME DATEPICKER *****************/

.calendar-table { display: none; }

.md-drppicker {
  width: 320px !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 2rem !important;
  align-items: flex-end !important;
  font-size: 14px !important;
  box-shadow: 0 10px 30px -10px #ACC2D9 !important;


  .calendar.left, .calendar.right {
    float: none !important;

    .calendar-time {
      background: transparent !important;

      .select {
        position: relative;

        &:first-child, &:nth-child(2) {
          background: #F1F5F9 !important;
          padding: 0.75rem 0.55rem !important;
          border-radius: 4px !important;
          margin-right: 0.9rem !important;
        }

        &:nth-child(2) { margin-right: 0 !important; }

        //.select-item.secondselect { display: none !important; }

        select.select-item {
          font-size: 14px !important;
          color: #ACC2D9;
          font-weight: 300;
          padding: 0.75rem 0.55rem !important;
        }
      }
    }
  }

  .calendar.right { margin-top: 0.75rem; }

  .calendar.left .select:first-child:before, .calendar.right .select:first-child:before {
    position: absolute;
    color: var(--font-primary);
  }

  .calendar.left .select:first-child:before {
    content: "Starts";
    left: -50px;
  }

  .calendar.right .select:first-child:before {
    content: "Ends";
    left: -45px;
  }

  .buttons {
    margin-top: 0.75rem !important;

    .btn {
      height: 39px !important;
      max-height: 39px !important;
      box-shadow: none !important;
      background-color: var(--button-primary) !important;
      color: var(--button-primary-font) !important;
      font-weight: 300 !important;
      font-size: 1rem !important;
      text-align: center !important;
      line-height: 1.5 !important;
      padding: 0.375rem 0.75rem !important;
      border: 1px solid transparent !important;
      border-radius: 4px !important;
      vertical-align: middle !important;
      text-transform: capitalize !important;
    }
  }

}
