@import 'abstracts/mixins';


.alert {
  padding: 20px 36px 22px 23px;

  &-primary {
    $primary: var(--button-light-primary);
    $dark-primary: var(--button-dark-primary);

    @include set-alert($primary, $dark-primary);

    p { color: var(--font-default) !important; }
  }

  &-seconday {
    $secondary: var(--button-light-secondary);
    $dark-secondary: var(--button-dark-secondary);

    @include set-alert($secondary, $dark-secondary);

    p { color: var(--font-default) !important; }
  }

  &-default {
    $default: var(--background-default);
    $dark-default: var(--font-default);

    @include set-alert($default, $dark-default);

    p { color: var(--font-default) !important; }
  }

  strong {
    color: var(--font-primary);
    font-weight: bold;
    font-size: 14px;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: var(--font-primary);
  }
}
