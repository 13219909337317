
.tooltip {
  .tooltip-inner { 
	  max-width: 400px; 
	  white-space: pre-wrap;
	  word-wrap: break-word;
	  text-align: left;
  }
}

