@import 'base/color';
@import 'base/typography';

textarea { background-color: var(--normal-state) !important; }

/* INPUT */
input, input[type=text], input[type=search], input[type=password], input[type=number], input[type=date] {
  min-width: 115px !important;
  border: 1px solid transparent !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  padding: 0.5rem 0.75rem 0.4375rem !important;
  border-radius: 4px !important;
  background-color: var(--normal-state);
  font-family: $radnika;

  @media(max-width: 768px) {
    & { font-size: 14px !important; }
  }

  &::placeholder { color: var(--font-primary) !important; }

  &:focus {
    border-color: var(--background-primary) !important;
    box-shadow: none !important;
  }

  &.saved {
    background-color: var(--saved-state) !important;
    border-color: transparent;
    color: var(--font-secondary);
  }

  &.submitted, &:disabled{
    //background: var(--submit-state);
    border-color: transparent;
    color: var(--font-tertiary);
  }  

  &.default {
    background: var(--normal-state) !important;
    color: var(--font-default);
  }

  &.notes {
    border-color: var(--button-light-default) !important;
    max-width: 100%;

    & ~ i {
      color: var(--button-primary);
      position: absolute;
      top: 3px;
      right: -12px;
      -webkit-transform: rotateZ(316deg);
      transform: rotateZ(316deg);
      font-size: 1.9rem;
      line-height: 0;
    }
  }
}

input[type=number] { padding-right: 0 !important; }

input.form-control-sm {
  height: 32px !important;
  padding: 8px 8px 6px;
}

select, textarea {
  font-size: 12px !important;
  border: 1px solid transparent !important;
}

/* CHECKBOX */
.form-check input[type="checkbox"]:not(:checked),
.form-check input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.form-check input[type="checkbox"]:not(:checked) + label,
.form-check input[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
}

/* CHECKBOX UNCHECKED ASPECT */
.form-check input[type="checkbox"]:not(:checked) + label:before,
.form-check input[type="checkbox"]:checked + label:before, 
.form-check .custom-control-label::before,
.form-check .custom-control-input:checked ~ .form-check .custom-control-label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid $checkBox;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: none !important;
}

.form-check .custom-control-input:focus:not(:checked) ~ .form-check .custom-control-label::before {
  border-color: $checkBox !important;
}

/* CHECKBOX CHECKED ASPECT */
.form-check input[type="checkbox"]:checked + label:after,
.form-check .custom-control-label::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  font-size: 1.3em;
  line-height: 0.8;
  transition: all .2s;
  box-shadow: none !important;
}


.form-check .custom-checkbox .custom-control-input:checked ~ .form-check .custom-control-label::after, 
.form-check input[type=checkbox]:checked + label:after, .form-check .custom-control-label::after { 
  background: $checkBoxCheked;
}

/* DISABLED CHECKBOX checkbox */

.form-check input[type="checkbox"]:disabled:not(:checked) + label:before,
.form-check input[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: $checkBoxDisabled;
}
.form-check input[type="checkbox"]:disabled:checked + label:after {
  background-color: $checkBoxDisabled;
}
.form-check input[type="checkbox"]:disabled + label {
  color: $checkBoxDisabled;
}

// SEARCH INPUT

.input-group {
  .search-input {
    margin-left: 0 !important;
    border-radius: 16px !important;
    padding-left: 44px !important;
    position: relative;
    z-index: 0 !important;
    border: none !important;
    background-color: #E7EBF9;
    font-size: 14px !important;
  }
  
  .search-input-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 16px;
    height: 16px;
    z-index: 1;
  }
}

.search-container {
  max-width: 365px;
  width: 100%;
}

