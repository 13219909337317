$sans-serif: sans-serif;
$assistant: Assistant-Light, #{$sans-serif};
$assistant-bold: Assistant-Regular, #{$sans-serif};

$radnika: Radnika, #{$sans-serif};

$lato: Lato, #{$sans-serif};


@mixin font-face($name, $path, $format, $weight) {
  @font-face {
    font-family: $name;
    src: url("#{$path}") format("#{$format}");
    font-weight: $weight;
    font-style: normal;
  }
}

@include font-face('Assistant', '/assets/fonts/assistant/Assistant-Light.ttf', 'truetype', 500);
@include font-face('Assistant-Regular', '/assets/fonts/assistant/Assistant-Regular.ttf', 'truetype', 300);

@include font-face('RadnikaNext', '/assets/fonts/radnika/RadnikaNext-Light.ttf', 'truetype', 300);
@include font-face('RadnikaNext', '/assets/fonts/radnika/RadnikaNext-Regular.ttf', 'truetype', 500);
@include font-face('RadnikaNext', '/assets/fonts/radnika/RadnikaNext-Medium.ttf', 'truetype', 600);
@include font-face('RadnikaNext', '/assets/fonts/radnika/RadnikaNext-Bold.ttf', 'truetype', 700);
@include font-face('RadnikaNext', '/assets/fonts/radnika/RadnikaNext-SemiBold.ttf', 'truetype', 800);
@include font-face('RadnikaNext', '/assets/fonts/radnika/RadnikaNext-ExtraBold.ttf', 'truetype', 900);

@include font-face('Radnika', '/assets/fonts/radnika/Radnika-Regular.ttf', 'truetype', 300);
@include font-face('Radnika', '/assets/fonts/radnika/Radnika-Regular.ttf', 'truetype', 400);
@include font-face('Radnika', '/assets/fonts/radnika/Radnika-Medium.ttf', 'truetype', 500);
@include font-face('Radnika', '/assets/fonts/radnika/Radnika-Bold.ttf', 'truetype', 700);



@include font-face('Lato', '/assets/fonts/lato/Lato-Light.ttf', 'truetype', 300);
@include font-face('Lato', '/assets/fonts/lato/Lato-Regular.ttf', 'truetype', 500);
@include font-face('Lato', '/assets/fonts/lato/Lato-Medium.ttf', 'truetype', 600);
@include font-face('Lato', '/assets/fonts/lato/Lato-Bold.ttf', 'truetype', 700);
