.panel {

  &.panel-open &-heading{ background-color: var(--background-secondary); }

  &-heading { background-color: var(--background-secondary); }

  &-title {

    button { color: #000000c4; }

  }
}
