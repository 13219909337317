@import 'base/color';


.table {
  width: 100%;
  border:none;
  border-collapse: collapse;
  margin-bottom: 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  //overflow: hidden !important;


  thead tr td { background-color: var(--table-primary); }

  th:first-of-type {
    border-radius: 10px 0 0;
  }

  th:last-of-type{
    border-radius: 0 10px 0;
  }
  th {
    span {
	  font-size: 12px;
      vertical-align: middle;
    }
  }

  th {
    padding: 15px;
    word-break: keep-all;
    white-space: nowrap;
    position: relative;

    // i {
    //   position: absolute;
    //   top: 35%;
    // }
  }

  td {
    padding: 0.75rem !important;
    font-size: 14px !important;
    font-weight: 300;
  }

  th, td {
    text-align: left;
    background-color: var(--table-primary);
    color: var(--font-primary);
    vertical-align: middle;
    border: 1px solid #FFFFFF !important;

    @media(max-width: 768px) {
      & { font-size: 14px !important; }
    }
  }



  th:first-child, td:first-child {
    border-left: none;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  &-responsive {
	  border-radius: 10px; //6 + 4 of the 2px margin
	  margin-bottom: 15px;
    overflow-x: unset;
  }

  &-striped tbody {
    tr > td,
    tr > th,
    tr > td > input.saved{
      background-color: var(--table-secondary);
    }

    tr {
      position: relative;

      td { position: static; }
    }

    tr:nth-child(odd) td, tr:nth-child(odd) td > input.saved { background-color: var(--table-tertiary); }
  }

  .default-color{ color: var(--font-secondary); }

  .no-border-y{ border-width: 0 !important; }

  .delete-row {
    position: absolute !important;
    background-color: white !important;
    top: auto;
    right: auto;
    margin-top: 8px;

    img { margin-top: 0.5rem; }
  }

  .total td{ background-color: var(--table-default); }

  @media(max-width: 768px) {
    .form-check { padding-left: 0.50rem; }
  }


  .table-striped tbody tr:first-of-type{
    background-color: none!important;
  }
}
