li {
  &.active {
    background-color: var(--background-tertiary);
  }
}

.navbar {
  background-color: var(--background-primary);
}

.nav-tabs .nav-link {
  min-width: 105px;
  text-align: center;
}
