@import 'abstracts/mixins';

.btn {
  height: 39px !important;
  line-height: 100%;
  max-height: 39px;
  font-weight: 400;

  @media(max-width: 768px) {
    & {
      font-size: 14px !important;
    }
  }

  &.btn {
    // line-height: 1 !important;
    //padding: 6px 16px 6px 16px !important;
    padding: 0.2rem 1.25rem 0.375rem !important;

    &-primary {
      $primary: var(--button-primary, var(--button-primary-font));
      @include set-button($primary);

      color: var(--button-primary-font) !important;
    }

    &-secondary {
      $secondary: var(--button-secondary, var(--button-secondary-font));
      @include set-button($secondary);

      color: var(--button-secondary-font) !important;
    }

    &-default {
      $default: var(--button-default, var(--button-default));
      @include set-button($default);
      color: var(--button-primary-font) !important;
      padding: 8px 15px 12px 17px !important;

      i {
        font-size: 18px;
        color: var(--font-primary) !important;
        line-height: 1.3;
      }

      @media(max-width: 768px) {
        & { padding: 0.75rem 0.5rem; }
      }
    }
	
	&-arrow {
        $default: var(--button-default, var(--button-default));
        @include set-button($default);
        color: var(--font-primary) !important;
		background-color: var(--table-tertiary) !important;
		border: 0px;
		width: 24px !important;
		height: 24px !important;
		line-height:0px !important;
		padding:0px !important;
		
        i {
          font-size: 24px;
          color: var(--font-primary) !important;
        }
	}
  }

  &.add {
    padding: 0.1875rem 0.75rem 0.25rem !important;

    i {
      font-size: 1.1rem;
      position: relative;
      top: 2px;
      padding-right: 2px;
    }
  }
}

.btn-group {
  width: 100%;
  background-color: #FFF;
  border-radius: 100px;
  position: relative;
  border: 2px solid var(--font-primary);
  background-color: var(--background-primary);

  .left, .right { 
      height: 34px;
      z-index: 1;
      font-family: $radnika;
      font-size: 1rem;
      color: #FFF;
      padding: 5px 20px;
      margin: 0;
  }

  .toggle {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      width: 50%;
      height: 34px;
      background-color: var(--background-default);
      border-right: 2px solid var(--font-primary);
      border-radius: 100px;
      transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .right.active, .left.active { color: var(--font-primary); }

  .right.active + .toggle {
      transform: translateX(100%);
      border-left: 2px solid var(--font-primary);
      border-right: none;
  }
}


