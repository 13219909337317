.dropdown-menu {
  min-width: 275px !important;
  width: auto !important;
  padding: 1.1875rem 0 !important;
  top: 45px !important;
  transform: none !important;

  .dropdown-item {
    font-size: 14px;
    color: var(--font-primary);
    padding: 0.25rem 1.375rem !important;

    span, strong { font-weight: 300 !important; }
  }
  
  .dropdown-item.active {
      font-size: 14px;
      color: var(--button-primary-font);
      background-color: var(--button-primary);
      padding: 0.25rem 1.375rem !important;

      span, strong { font-weight: 300 !important; }
  }
}

.dropdown-toggle {
	margin-left: 1px;
	background-color: var(--table-tertiary) !important;
	border: 0px;
}
