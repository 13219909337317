.progress {
    height: 6px !important;

    .progress-bar {
        &.bg-success { background-color: #83DEF6 !important; }
    
        &.bg-warning { background-color: #F79256 !important; }
    
        &.bg-danger { background-color: #EF7786 !important; }
    
        &.bg-info { background-color: #3E5BA3 !important; }
    }
}

