@import 'base/color';
@import 'base/typography';

body {
  overflow: hidden;
  background-color: #FFF;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: $radnika !important;
  line-height: 19px;
}

footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: $light-gray;
}

.alert { font-size: 0.9rem; }

.cursor--pointer { cursor: pointer; }

.paragraph-allowance {
  padding-top: 0.1rem;
  width: 130px;
  max-width: 100%;
  text-align: center;
  color: var(--font-primary);
  font-weight: 400;
}


@media (max-width: 1024px) {
  .overflow-scroll{
    overflow: auto;
    scrollbar-width: thin;
  }
}


.introjs-tooltip-heade, .introjs-prevbutton, .introjs-tooltip-title{
  display: none!important;
}

.introjs-tooltipReferenceLayer *{
  font-family: $radnika;
}


.modal {
  overflow-y: hidden!important;
}

.fullScreenModal {
  width:100% ;
  max-width: 100%!important;
  height: 100%;
  top: 0!important;
  margin:0;
}

.fullScreenModal .modal-content {
  width: 100%;
  height: 100%;
  margin: 0;
}

