@import 'base/color';


$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "default": $default
);

:root {
  --tab-active: #eaf3ff;
  --table-striped-active: #eaf3ff
}

@import '~node_modules/bootstrap/scss/bootstrap';
