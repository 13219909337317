.modal {

  &-dialog {
    position: relative;
    top: 10rem;
  }

  &-content {
    border: none !important;

    .modal-header {
      padding: 1.5625rem 1rem;

      .modal-title {
        color: var(--font-primary);
        font-size: 16px;
        font-weight: 700;
      }
    }

    .modal-body {
      padding: 0;
      font-weight: 300;


      .table-responsive { overflow-y: auto; }
    }
  }
}

.modal-backdrop.show { opacity: 0.3 !important; }

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 900px !important;
  }
}

.confirmation-modal{
  border: 1px solid #00abc714;
}