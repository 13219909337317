.badge {
  width: 18px;
  height: 18px;
  padding: 1px;
  font-size: 12px;
  line-height: 19px;

  &-primary {
    background-color:  var(--badge-primary);
    color: var(--font-secondary);
  }
}
