@import 'base/color';

.form {

  input::placeholder {
    color: $light-gray;
  }

  &__status {
    font-size: 0.9rem;

    &--valid { color: $green; }

    &--invalid { color: red; }
  }

}
