@import 'typography';

%height-max { height: 100vh; }


* {
  margin: 0;
  padding: 0;
  border: 0;
}

html { @extend %height-max; }

body {
  @extend %height-max;

  overflow-y: scroll !important;
  font-family: $assistant;
}

a, a:hover { text-decoration: unset; }

ul, li { list-style-type: none; }


.flex-gap-1 {
  gap: 15px;
}