/* BCLOCK COLORS */

$primary: #83DEF6;
$secondary: #3E5BA3;
$default: #E7EBF9;

$fontPrimary: #2F4A73;
$buttonFont: #FFFFFF;

$inputDefault: #F1F5F9;

$checkBox: #7692E3;
$checkBoxCheked: #3E5BA3;
$checkBoxDisabled: #98A4CA;

$boxShadow: #ACC2D9;
$whiteSmoke: #f8fafc;

$gray: #7F8FA4;
$light-gray: #9e9b9b;
$sky-gray: #f8fafc;

$green: #29a745;


/* SEMIC COLORS */
/*
$primary: #94005F;
$secondary: #94005F;
$fontPrimary: #414745;
$buttonFont: #FFFFFF;

$inputDefault: #EFEFEF;

$checkBox: #94005F;
$checkBoxCheked: #670042;
$checkBoxDisabled: #b44c8f;
*/
