.pagination {
  margin-bottom: 0;
  flex-wrap: wrap;
}

.page-item {
  &.active .page-link {
    background-color: var(--button-primary);
    border-color: var(--button-primary);
    color: var(--button-primary-font) !important;
    z-index: 0 !important;
  }

  .page-link {
    color: var(--font-primary);

    @media(max-width: 768px) {
      & {
        max-height: 39px;
        height: 39px;
        font-size: 14px;
        padding: 0.7rem 0.75rem;
      }
    }
  }

  &.pagination-next a, &.pagination-prev a {
    color: var(--font-primary) !important;
    font-weight: 300;
  }
}

