@import 'base/color';

// Media Query
@mixin media($type, $breakpoint) {
  @media screen and (#{$type}: $breakpoint) {
    @content;
  }
}

// Keyframes
@mixin keyframes($animationName)
{
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

// Set Button Color
@mixin set-button($color) {
  & {
    background-color: $color;
    border-color: $color;

    &:hover { background-color: $color; }
  }
}

// Set Alert Color
@mixin set-alert($color, $text-color) {
  color: $text-color;
  background-color: $color;
  border-color: $color;
}
